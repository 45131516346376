var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "columns is-multiline mt-0 mb-0 is-vcentered is-variable is-1 is-mobile",
    },
    [
      _c(
        "div",
        { staticClass: "column is-narrow pt-1 pb-1" },
        [
          _c(
            "toggle-switch",
            {
              model: {
                value: _vm.isRequests,
                callback: function ($$v) {
                  _vm.isRequests = $$v
                },
                expression: "isRequests",
              },
            },
            [
              _c("option", { domProps: { value: true } }, [
                _vm._v(_vm._s(_vm.labels.requests)),
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: false } }, [
                _vm._v(_vm._s(_vm.labels.placeholders)),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.search && (!_vm.requests || _vm.filters.type !== "hotel")
        ? _c("div", { staticClass: "column is-narrow pt-1 pb-1" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm._search,
                  expression: "_search",
                },
              ],
              staticClass: "input is-smaller",
              attrs: { type: "text", placeholder: "Suchen" },
              domProps: { value: _vm._search },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm._search = $event.target.value
                },
              },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.requests && _vm.filters.type === "hotel"
        ? _c(
            "div",
            { staticClass: "column is-narrow pt-1 pb-1" },
            [
              _c("async-select", {
                staticClass: "is-smaller",
                attrs: { api: "hotels", placeholder: "Hotel" },
                model: {
                  value: _vm.filters.selectedHotel,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters, "selectedHotel", $$v)
                  },
                  expression: "filters.selectedHotel",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.placeholders ? _vm._t("default") : _vm._e(),
      _vm._v(" "),
      _vm.requests
        ? _c(
            "div",
            { staticClass: "column is-narrow pt-1 pb-1" },
            [
              _c("async-select", {
                staticClass: "is-smaller",
                attrs: { api: "agencies", placeholder: "Agentur filtern" },
                model: {
                  value: _vm.filters.agency,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters, "agency", $$v)
                  },
                  expression: "filters.agency",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.requests ? [_vm._t("default")] : _vm._e(),
      _vm._v(" "),
      _vm.placeholders ? _vm._t("after") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column is-narrow pt-1 pb-1 pr-0" },
        [_c("color-switch", { on: { input: _vm.setOrderStatus } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column is-narrow pt-1 pb-1 pl-0" },
        [
          _c("multiselect", {
            staticClass: "is-smaller",
            attrs: {
              options: _vm.options.orderStatus ? _vm.options.orderStatus : [],
              "track-by": "key",
              label: "value",
              multiple: "",
              placeholder: "Status filtern",
            },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function ({ values, search, isOpen }) {
                  return [
                    values.length && !isOpen
                      ? _c("span", { staticClass: "multiselect__single" }, [
                          _vm._v(_vm._s(values.length) + " Stati ausgewählt"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.orderStatus,
              callback: function ($$v) {
                _vm.orderStatus = $$v
              },
              expression: "orderStatus",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "column is-narrow pt-1 pb-1" }, [
        _c(
          "div",
          { staticClass: "flatpickr-combined" },
          [
            _c("input-date", {
              model: {
                value: _vm.filters.startAt,
                callback: function ($$v) {
                  _vm.$set(_vm.filters, "startAt", $$v)
                },
                expression: "filters.startAt",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("-")]),
            _vm._v(" "),
            _c("input-date", {
              model: {
                value: _vm.filters.endAt,
                callback: function ($$v) {
                  _vm.$set(_vm.filters, "endAt", $$v)
                },
                expression: "filters.endAt",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column is-narrow pt-1 pb-1 pr-0" },
        [
          _vm.requests
            ? _c("color-switch", { on: { input: _vm.setRequestStatus } })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.requests
        ? _c(
            "div",
            { staticClass: "column is-narrow pt-1 pb-1 pl-0" },
            [
              _c("multiselect", {
                staticClass: "is-smaller",
                attrs: {
                  options: _vm.options.status ? _vm.options.status : [],
                  multiple: "",
                  label: "value",
                  "track-by": "key",
                  placeholder: "L-Status filtern",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "selection",
                      fn: function ({ values, search, isOpen }) {
                        return [
                          values.length && !isOpen
                            ? _c(
                                "span",
                                { staticClass: "multiselect__single" },
                                [
                                  _vm._v(
                                    _vm._s(values.length) + " Stati ausgewählt"
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2465214594
                ),
                model: {
                  value: _vm.requestStatus,
                  callback: function ($$v) {
                    _vm.requestStatus = $$v
                  },
                  expression: "requestStatus",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.placeholders
        ? _c(
            "div",
            { staticClass: "column is-narrow pt-1 pb-1" },
            [
              _c("multiselect", {
                staticClass: "is-smaller",
                attrs: {
                  options: _vm.clientOfferStatuses,
                  label: "value",
                  "track-by": "key",
                  placeholder: "im Paket?",
                },
                model: {
                  value: _vm.clientOfferStatus,
                  callback: function ($$v) {
                    _vm.clientOfferStatus = $$v
                  },
                  expression: "clientOfferStatus",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.placeholders
        ? _c(
            "div",
            { staticClass: "column is-narrow pt-1 pb-1" },
            [
              _c("multiselect", {
                staticClass: "is-smaller",
                attrs: {
                  options: _vm.requestStatuses,
                  "track-by": "value",
                  label: "label",
                  placeholder: "LT-Status",
                },
                model: {
                  value: _vm.notRequestStatus,
                  callback: function ($$v) {
                    _vm.notRequestStatus = $$v
                  },
                  expression: "notRequestStatus",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }