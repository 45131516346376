<template>
    <div>
        <filter-default v-model="filters" v-bind:options="options" requests>
            <div class="column is-narrow pt-1 pb-1">
                <div class="flatpickr-combined">
                    <async-select
                        api="airports"
                        placeholder="Abflug"
                        v-model="filters.airline.startAirport"
                        class="is-smaller"
                    />

                    <span>-</span>

                    <async-select
                        api="airports"
                        placeholder="Ankunft"
                        v-model="filters.airline.endAirport"
                        class="is-smaller"
                    />
                </div>
            </div>
        </filter-default>
    </div>
</template>

<script>
import value, { options } from '../_mixins';
import FilterDefault from '../Default';
import { AsyncSelect } from '@components/form'

export default {
    mixins: [ value, options ],

    components: {
        FilterDefault,
        AsyncSelect
    }
}
</script>
