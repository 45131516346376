var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("layout", [
    !_vm.isGrantedBookKeeper
      ? _c(
          "div",
          { staticClass: "is-user-dashboard" },
          [
            _c(
              "div",
              [
                _c("orders", { on: { refreshTodos: _vm.refreshTodos } }),
                _vm._v(" "),
                _c("todos", {
                  ref: "todosComponent",
                  staticClass: "is-hidden-mobile is-hidden-tablet-only",
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("tour"),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }