var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "filter-default",
        {
          attrs: { options: _vm.options, placeholders: "" },
          model: {
            value: _vm.filters,
            callback: function ($$v) {
              _vm.filters = $$v
            },
            expression: "filters",
          },
        },
        [
          _c("template", { slot: "after" }, [
            _c(
              "div",
              { staticClass: "column is-narrow pt-1 pb-1" },
              [
                _c("multiselect", {
                  staticClass: "is-smaller",
                  attrs: {
                    options: _vm.categories,
                    label: "value",
                    "track-by": "key",
                    placeholder: "Other Service Type category",
                  },
                  model: {
                    value: _vm.category,
                    callback: function ($$v) {
                      _vm.category = $$v
                    },
                    expression: "category",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column is-narrow pt-1 pb-1" },
              [
                _c("location-select", {
                  staticClass: "is-smaller",
                  attrs: {
                    places: false,
                    hasDestination: "",
                    placeholder: "Area/destination",
                  },
                  model: {
                    value: _vm.filters.location,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "location", $$v)
                    },
                    expression: "filters.location",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }