var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "columns pt-0 pb-0 mt-0 mb-0 is-vcentered" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "column pt-0 pb-0" }, [
        _c(
          "div",
          {
            staticClass:
              "columns is-multiline mt-0 mb-0 is-vcentered is-variable is-1 is-mobile",
          },
          [
            _c("div", { staticClass: "column is-narrow pt-1 pb-1" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm._search,
                    expression: "_search",
                  },
                ],
                staticClass: "input is-medium",
                attrs: { type: "text", placeholder: "Suchen" },
                domProps: { value: _vm._search },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm._search = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "column is-narrow pt-1 pb-1" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filters.tripName,
                    expression: "filters.tripName",
                  },
                ],
                staticClass: "input is-medium is-hidden-on-small",
                attrs: {
                  type: "text",
                  placeholder: "Reisetitel oder ResaNr suchen",
                },
                domProps: { value: _vm.filters.tripName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.filters, "tripName", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column is-narrow pt-1 pb-1" },
              [
                _c("async-select", {
                  staticClass: "is-smaller is-shorter",
                  attrs: {
                    api: "destinations",
                    placeholder: "Destinationen",
                    multiple: "",
                  },
                  model: {
                    value: _vm.filters.destinations,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "destinations", $$v)
                    },
                    expression: "filters.destinations",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column is-narrow pt-1 pb-1" },
              [
                _c(
                  "multiselect",
                  {
                    staticClass: "is-smaller is-hidden-on-small is-shorter",
                    attrs: {
                      "track-by": "label",
                      label: "name",
                      options: _vm.resources.countries,
                      placeholder: "Land",
                    },
                    model: {
                      value: _vm.filterCountry,
                      callback: function ($$v) {
                        _vm.filterCountry = $$v
                      },
                      expression: "filterCountry",
                    },
                  },
                  [
                    !!_vm.filterCountry
                      ? _c("div", {
                          staticClass: "multiselect__clear",
                          attrs: { slot: "clear" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              _vm.filterCountry = ""
                            },
                          },
                          slot: "clear",
                        })
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column is-narrow pt-1 pb-1 pr-0" },
              [_c("color-switch", { on: { input: _vm.setOrderStatus } })],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column is-narrow pt-1 pb-1 pl-0" },
              [
                _c("multiselect", {
                  staticClass: "is-smaller is-shorter",
                  attrs: {
                    options: _vm.options.orderStatus
                      ? _vm.options.orderStatus
                      : [],
                    "track-by": "key",
                    label: "value",
                    multiple: "",
                    placeholder: "Status filtern",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "selection",
                      fn: function ({ values, search, isOpen }) {
                        return [
                          values.length && !isOpen
                            ? _c(
                                "span",
                                { staticClass: "multiselect__single" },
                                [
                                  _vm._v(
                                    _vm._s(values.length) + " Stati ausgewählt"
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.orderStatus,
                    callback: function ($$v) {
                      _vm.orderStatus = $$v
                    },
                    expression: "orderStatus",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "column is-narrow pt-1 pb-1" }, [
              _c(
                "div",
                { staticClass: "flatpickr-combined" },
                [
                  _c("input-date", {
                    model: {
                      value: _vm.filters.startAt,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "startAt", $$v)
                      },
                      expression: "filters.startAt",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("-")]),
                  _vm._v(" "),
                  _c("input-date", {
                    model: {
                      value: _vm.filters.endAt,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "endAt", $$v)
                      },
                      expression: "filters.endAt",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column is-narrow pt-1 pb-1" },
              [
                _vm.resources.organisations.length > 1
                  ? _c(
                      "multiselect",
                      {
                        staticClass: "is-smaller is-shorter",
                        attrs: {
                          options: _vm.resources.organisations,
                          "track-by": "id",
                          label: "name",
                          placeholder: "Organisation",
                        },
                        model: {
                          value: _vm.filterOrganisation,
                          callback: function ($$v) {
                            _vm.filterOrganisation = $$v
                          },
                          expression: "filterOrganisation",
                        },
                      },
                      [
                        !!_vm.filterOrganisation
                          ? _c("div", {
                              staticClass: "multiselect__clear",
                              attrs: { slot: "clear" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  _vm.filterOrganisation = ""
                                },
                              },
                              slot: "clear",
                            })
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column is-narrow pt-1 pb-1" },
              [
                _c(
                  "multiselect",
                  {
                    staticClass: "is-small is-shorter",
                    attrs: {
                      options:
                        _vm.options && _vm.options.orderTags
                          ? _vm.options.orderTags
                          : [],
                      taggable: true,
                      multiple: "",
                      placeholder: "Tags",
                    },
                    on: { tag: _vm.addTag },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function ({ values, search, isOpen }) {
                          return [
                            values.length && !isOpen
                              ? _c(
                                  "span",
                                  { staticClass: "multiselect__single" },
                                  [_vm._v(_vm._s(values.length) + " Tags")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.filterTags,
                      callback: function ($$v) {
                        _vm.filterTags = $$v
                      },
                      expression: "filterTags",
                    },
                  },
                  [
                    _vm._v(" "),
                    _c("span", { attrs: { slot: "caret" }, slot: "caret" }),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-narrow pt-1 pb-1" }, [
      _c("i", { staticClass: "fa fa-filter fa-lg" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }