<template>


            <div class="columns is-multiline  mt-0 mb-0 is-vcentered is-variable is-1 is-mobile">
                <div class="column is-narrow pt-1 pb-1" >
                    <toggle-switch v-model="isRequests">
                        <option v-bind:value="true">{{labels.requests}}</option>
                        <option v-bind:value="false">{{labels.placeholders}}</option>
                    </toggle-switch>
                </div>
                <div class="column is-narrow pt-1 pb-1" v-if="search && (!requests || filters.type !== 'hotel')">
                    <input
                        type="text"
                        v-model="_search"
                        class="input is-smaller "
                        placeholder="Suchen"
                    >
                </div>
                <div class="column is-narrow pt-1 pb-1" v-if="requests && filters.type === 'hotel'">
                    <async-select
                        api="hotels"
                        v-model="filters.selectedHotel"
                        placeholder="Hotel"
                        class="is-smaller "
                    />
                </div>
                <slot v-if="placeholders"></slot>
                <div class="column is-narrow pt-1 pb-1" v-if="requests">
                    <async-select
                        api="agencies"
                        v-model="filters.agency"
                        placeholder="Agentur filtern"
                        class="is-smaller "
                    />
                </div>

                <template v-if="requests">
                    <slot />
                </template>

                <slot name="after" v-if="placeholders"></slot>
                <div class="column is-narrow pt-1 pb-1 pr-0">
                    <color-switch @input="setOrderStatus"></color-switch>
                </div>
                <div class="column is-narrow pt-1 pb-1 pl-0">
                    <multiselect
                        v-model="orderStatus"
                        v-bind:options="options.orderStatus ? options.orderStatus : []"
                        track-by="key"
                        label="value"
                        multiple
                        class="is-smaller "
                        placeholder="Status filtern"
                    >
                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                            <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} Stati ausgewählt</span>
                        </template>
                    </multiselect>
                </div>
                <div class="column is-narrow pt-1 pb-1">
                    <div class="flatpickr-combined ">
                        <input-date v-model="filters.startAt" />
                        <span>-</span>
                        <input-date v-model="filters.endAt" />
                    </div>
                </div>
                <div class="column is-narrow pt-1 pb-1 pr-0">
                    <color-switch @input="setRequestStatus" v-if="requests"></color-switch>
                </div>
                <div class="column is-narrow pt-1 pb-1 pl-0" v-if="requests">
                    <multiselect
                        v-model="requestStatus"
                        v-bind:options="options.status ? options.status : []"
                        multiple
                        label="value"
                        track-by="key"
                        placeholder="L-Status filtern"
                        class="is-smaller "
                    >
                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                            <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} Stati ausgewählt</span>
                        </template>
                    </multiselect>
                </div>
                <div class="column is-narrow pt-1 pb-1" v-if="placeholders">
                    <multiselect
                        v-bind:options="clientOfferStatuses"
                        label="value"
                        track-by="key"
                        placeholder="im Paket?"
                        v-model="clientOfferStatus"
                        class="is-smaller "
                    />
                </div>
                <div class="column is-narrow pt-1 pb-1" v-if="placeholders">
                    <multiselect
                        v-bind:options="requestStatuses"
                        track-by="value"
                        label="label"
                        placeholder="LT-Status"
                        v-model="notRequestStatus"
                        class="is-smaller "
                    />
                </div>
            </div>
</template>

<script>
import debounce from 'lodash/debounce';
import value, { options } from './_mixins';
import {InputDate, AsyncSelect, Multiselect, ToggleSwitch} from '@components/form'
import ColorSwitch from "@components/form/ColorSwitch.vue";
import isEqual from "lodash/isEqual";

export default {
    mixins: [ value, options ],

    components: {
        ToggleSwitch,
        ColorSwitch,
        InputDate,
        AsyncSelect,
        Multiselect
    },

    props: {
        search: {
            type: Boolean,
            default: true,
        },

        requests: {
            type: Boolean,
            default: false,
        },

        placeholders: {
            type: Boolean,
            default: false,
        }
    },

    data: function () {
        return {
            requestStatuses: [
                { label: 'noch nicht angefragt', value: 'no_requests' },
                { label: 'noch nicht gebucht', value: 'no_option' },
                { label: 'noch nicht gesichert', value: 'not_approved' },
            ]
        }
    },

    computed: {
        notRequestStatus: {
            get: function () {
                return this.requestStatuses
                    .find(({value}) => this.filters.notRequestStatus === value)
            },
            set: function (status) {
                this.filters.notRequestStatus = !!status ? status.value : null
            }
        },

        _search: {
            get: function () { return this.filters._search },
            set: debounce(function (value) {
                this.$emit('input', {...this.value, _search: value})
            }, 600)
        },

        labels: function () {
            if(this.filters.type === 'hotel') {
                return {
                    requests: 'von Hotel',
                    placeholders: 'im Raum',
                }
            }
            if(this.filters.type === 'ferry') {
                return {
                    requests: 'von Fähre',
                    placeholders: 'für Fährstrecke',
                }
            }
            if(this.filters.type === 'other') {
                return {
                    requests: 'von Leistungsträger',
                    placeholders: 'für Leistungsart',
                }
            }
            if(this.filters.type === 'airline') {
                return {
                    requests: 'von Airline',
                    placeholders: 'für Flugstrecke',
                }
            }
            if(this.filters.type === 'train') {
                return {
                    requests: 'von Zuggesellschaft',
                    placeholders: 'für Zugverbindung',
                }
            }
            return {
                requests: 'requests',
                placeholders: 'placeholder',
            }
        },

        isRequests: {
            get: function () { return this.value.isRequests },
            set: function (value) { this.$emit('input', {...this.value, isRequests: value}) }
        }
    },

    methods: {
        setOrderStatus: function (value) {

            let inActives = ['deleted', 'offer_declined', 'booking_cancelled']
            let oldStatuses = this.options.orderStatus.filter(item => this.filters.orderStatus.includes(item.key));
            let newStatuses = this.options.orderStatus.filter(item => value === 'is-green' ? !inActives.includes(item.key) : inActives.includes(item.key));

            this.orderStatus = isEqual(oldStatuses, newStatuses) ? [] : newStatuses;
        },

        setRequestStatus: function (value) {

            let inActives = ['called_off', 'declined', 'canceled']
            let oldStatuses = this.options.status.filter(item => this.filters.requestStatus.includes(item.key));
            let newStatuses = this.options.status.filter(item => value === 'is-green' ? !inActives.includes(item.key) : inActives.includes(item.key));

            this.requestStatus = isEqual(oldStatuses, newStatuses) ? [] : newStatuses;
        }
    }
}
</script>
