var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isRequests
        ? _c("requests", {
            attrs: { options: _vm.options },
            model: {
              value: _vm.filters,
              callback: function ($$v) {
                _vm.filters = $$v
              },
              expression: "filters",
            },
          })
        : _c("placeholders", {
            attrs: { options: _vm.options },
            model: {
              value: _vm.filters,
              callback: function ($$v) {
                _vm.filters = $$v
              },
              expression: "filters",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }