<template>
    <div >
        <requests v-if="isRequests" v-model="filters" v-bind:options="options" />
        <placeholders v-else v-model="filters" v-bind:options="options" />
    </div>
</template>

<script>
import { ToggleSwitch } from '@components/form';
import value from './value'
import options from './options';

export default {
    mixins: [ value, options ],

    labels: {
        requests: 'requests',
        placeholders: 'placeholders'
    },

    computed: {
        labels: function () { return this.$options.labels },

        isRequests: {
            get: function () { return this.value.isRequests },
            set: function (value) { this.$emit('input', {...this.value, isRequests: value}) }
        }
    },

    components: {
        ToggleSwitch,
    }
}
</script>
