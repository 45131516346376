<template>
    <div >
        <div class="columns pt-0 pb-0 mt-0 mb-0 is-vcentered">
            <div class="column is-narrow pt-1 pb-1">
                <i class="fa fa-filter fa-lg"></i>
            </div>
            <div class="column pt-0 pb-0">
                <div class="columns is-multiline  mt-0 mb-0 is-vcentered is-variable is-1 is-mobile">
                    <div class="column is-narrow pt-1 pb-1">
                        <input
                            type="text"
                            v-model="_search"
                            class="input is-medium "
                            placeholder="Suchen"
                        >
                    </div>
                    <div class="column is-narrow pt-1 pb-1">
                        <input
                            type="text"
                            v-model="filters.tripName"
                            class="input is-medium  is-hidden-on-small"
                            placeholder="Reisetitel oder ResaNr suchen"
                        >
                    </div>
                    <div class="column is-narrow pt-1 pb-1">
                        <async-select
                            api="destinations"
                            v-model="filters.destinations"
                            class="is-smaller  is-shorter"
                            placeholder="Destinationen"
                            multiple
                        />
                    </div>
                    <div class="column is-narrow pt-1 pb-1">
                        <multiselect
                            track-by="label"
                            label="name"
                            class="is-smaller  is-hidden-on-small is-shorter"

                            v-bind:options="resources.countries"
                            v-model="filterCountry"
                            placeholder="Land"
                        >
                            <div
                                slot="clear"
                                class="multiselect__clear"
                                v-if="!!filterCountry"
                                v-on:click.prevent.stop="filterCountry = ''"
                            ></div>
                        </multiselect>
                    </div>
                    <div class="column is-narrow pt-1 pb-1 pr-0">
                        <color-switch @input="setOrderStatus"></color-switch>
                    </div>
                    <div class="column is-narrow pt-1 pb-1 pl-0">
                        <multiselect
                            v-model="orderStatus"
                            v-bind:options="options.orderStatus ? options.orderStatus : []"
                            track-by="key"
                            label="value"
                            multiple
                            class="is-smaller  is-shorter"
                            placeholder="Status filtern"
                        >
                            <template slot="selection" slot-scope="{ values, search, isOpen }">
                                <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} Stati ausgewählt</span>
                            </template>
                        </multiselect>
                    </div>
                    <div class="column is-narrow pt-1 pb-1">
                        <div class="flatpickr-combined ">
                            <input-date v-model="filters.startAt" />
                            <span>-</span>
                            <input-date v-model="filters.endAt" />
                        </div>
                    </div>
                    <div class="column is-narrow pt-1 pb-1">
                        <multiselect
                            v-if="resources.organisations.length > 1"
                            v-bind:options="resources.organisations"
                            v-model="filterOrganisation"
                            class="is-smaller is-shorter "
                            track-by="id"
                            label="name"
                            placeholder="Organisation"
                        >
                            <div
                                slot="clear"
                                class="multiselect__clear"
                                v-if="!!filterOrganisation"
                                v-on:click.prevent.stop="filterOrganisation = ''"
                            ></div>
                        </multiselect>
                    </div>
                    <div class="column is-narrow pt-1 pb-1">
                        <multiselect
                            :options="options && options.orderTags ? options.orderTags : []"
                            class="is-small is-shorter "

                            v-model="filterTags"
                            v-bind:taggable="true"
                            multiple
                            placeholder="Tags"
                            v-on:tag="addTag"
                        >
                            <template slot="selection" slot-scope="{ values, search, isOpen }">
                                <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} Tags</span>
                            </template>
                            <span slot="caret" ></span>
                        </multiselect>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import value, { options } from './_mixins';
import debounce from 'lodash/debounce';
import { AsyncSelect, Multiselect, InputDate } from '@components/form'
import ColorSwitch from "@components/form/ColorSwitch";
import isEqual from "lodash/isEqual";

export default {
    mixins: [ value, options ],

    components: {
        ColorSwitch,
        AsyncSelect,
        Multiselect,
        InputDate
    },

    computed: {
         _search: {
            get: function () { return this.filters._search },
            set: debounce(function (value) {
                this.$emit('input', {...this.value, _search: value})
            }, 600)
        }
    },

    methods: {
        setOrderStatus: function (value) {

            let inActives = ['deleted', 'offer_declined', 'booking_cancelled']
            let oldStatuses = this.options.orderStatus.filter(item => this.filters.orderStatus.includes(item.key));
            let newStatuses = this.options.orderStatus.filter(item => value === 'is-green' ? !inActives.includes(item.key) : inActives.includes(item.key));



            this.orderStatus = isEqual(oldStatuses, newStatuses) ? [] : newStatuses;
        },
        addTag (tag) {
            let tags = this.filterTags || [];
            tags.push(tag);
            this.filterTags   = tags;
        },
    }
}
</script>
