var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "filter-default",
        {
          attrs: { options: _vm.options, requests: "" },
          model: {
            value: _vm.filters,
            callback: function ($$v) {
              _vm.filters = $$v
            },
            expression: "filters",
          },
        },
        [
          _c(
            "div",
            { staticClass: "column is-narrow pt-1 pb-1" },
            [
              _c("multiselect", {
                staticClass: "is-smaller",
                attrs: {
                  options: _vm.categories,
                  label: "value",
                  "track-by": "key",
                  placeholder: "Other Service Type category",
                },
                model: {
                  value: _vm.category,
                  callback: function ($$v) {
                    _vm.category = $$v
                  },
                  expression: "category",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }