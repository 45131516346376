<template>
    <div>
        <filter-default v-model="filters" v-bind:options="options" requests>
            <div class="column is-narrow pt-1 pb-1">
                <async-select
                    api="areas"
                    placeholder="Region filtern"
                    v-model="filters.hotel.area"
                    class="is-smaller"
                />
            </div>
            <div class="column is-narrow pt-1 pb-1">
                <async-select
                    api="destinations"
                    placeholder="Destination filtern"
                    v-model="filters.hotel.destination"
                    class="is-smaller"
                />
            </div>
        </filter-default>
    </div>
</template>

<script>
import { AsyncSelect } from '@components/form'
import value, { options } from '../_mixins';
import FilterDefault from '../Default';

export default {
    mixins: [ value, options ],

    components: {
        FilterDefault,
        AsyncSelect
    }
}
</script>
