<template>
    <div>
        <filter-default v-model="filters" v-bind:options="options" placeholders>
            <template slot="after">
                <div class="column is-narrow pt-1 pb-1">
                    <multiselect
                        v-bind:options="categories"
                        label="value"
                        track-by="key"
                        v-model="category"
                        placeholder="Other Service Type category"
                        class="is-smaller"
                    />
                    </div>
                <div class="column is-narrow pt-1 pb-1">


                    <location-select
                    v-bind:places="false"
                    hasDestination
                    v-model="filters.location"
                    class="is-smaller"
                    placeholder="Area/destination"
                    />
                </div>
            </template>
        </filter-default>
    </div>
</template>

<script>
import value, { options } from '../_mixins';
import FilterDefault from '../Default';
import { Multiselect, LocationSelect } from '@components/form'

export default {
    mixins: [ value, options ],

    components: {
        FilterDefault,
        Multiselect,
        LocationSelect
    },

    computed: {
        categories: function () {
            return this.options.otherServiceTypeCategories ? this.options.otherServiceTypeCategories : []
        },

        category: {
            get: function () { return this.categories.find(category => category.key === this.filters.other.otherServiceTypeCategory) },
            set: function (value) { this.filters.other.otherServiceTypeCategory = !!value ? value.key : null }
        }
    }
}
</script>
