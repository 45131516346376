<template>
    <crud-table
        ref="table"
        class="orders"

        :key="api"
        v-bind:api="api"
        v-bind:url="rowURL"
        target="_blank"

        v-bind:deleteRow="false"

        v-bind:filter="filter"
        :order="assignFilter"
        loadOptions="user-dashboard"

        v-bind:columns="columns"
        v-bind:name="{singular: 'Element', plural: 'Elementen' }"
        storeKey="user-dashboard/list"

        :checkable="isRequests"
        v-model="checked"
    >
        <table-filters
            slot="filters"
            slot-scope="{options}"
            v-model="filters"
            :type="type"
            :options="options"
            data-v-step="filters"
        />

        <template slot="header">
            <div class="level" style="width: 100%">
                <div class="level-left">
                    <h2 class="level-item">REISEÜBERSICHT:</h2>
                    <toggle-switch :value="type" v-on:input="changeType" data-v-step="filters:type">
                        <option value="orders">
                            <i class="material-icons">folder</i>
                        </option>
                        <option value="hotel">
                            <i class="material-icons">local_hotel</i>
                        </option>
                        <option value="ferry">
                            <i class="material-icons">directions_boat</i>
                        </option>
                        <option value="train">
                            <i class="material-icons">directions_train</i>
                        </option>
                        <option value="airline">
                            <i class="material-icons">local_airport</i>
                        </option>
                        <option value="other">
                            <i class="material-icons">more_horiz</i>
                        </option>
                    </toggle-switch>
                </div>

                <div class="level-right mt-1">
                    <export-button
                        v-if="!isOrders"
                        class="level-item is-hidden-mobile"
                        v-on:export="handleExport"
                    />
                    <drop-zone class="level-item" v-if="showSelectFile" @documentSaved="handleOrderImport"></drop-zone>

                    <simple-menu :material="false" class="level-item is-hidden-mobile" icon="fa fa-file-excel-o" title="Download/Upload Excel" v-if="isOrders && !buttonIsLoading && isGrantedAdmin">
                        <button v-on:click.prevent="handleOrderExport('orders/generate-excel-reservations', moment().format('YYYY - MM - DD') + ' - Reservierungsliste.xlsx')">Download Reservierungsliste</button>
                        <button v-on:click.prevent="handleOrderExport('orders/generate-excel')">Download Reiseeinteilung</button>
                        <button v-on:click.prevent="showSelectFile=true">Upload Reiseeinteilung</button>
                    </simple-menu>
                    <loading text-after="" text-in-front="" class="is-small p-0" v-if="buttonIsLoading"></loading>


                    <a class="level-item button is-success mb-0" href="/orders/add" title="Neue Reise hinzufügen" target="_blank" >
                        <i class="material-icons">add</i> Neue Reise
                    </a>

                    <user-image
                        v-bind:user="filters.users"
                        small
                        editable
                        quick
                        v-on:input="changeUsers"
                        data-v-step="filters:users"
                    />
                </div>
            </div>
        </template>

        <div slot="footer" slot-scope="{checked, options}" v-if="isRequests">
            <div class="level level-left">
                <toggle-switch v-model="requestAction.type" class="level-item">
                    <option value="status"><i class="material-icons">import_contacts</i></option>
                    <option value="deadline"><i class="material-icons">date_range</i></option>
                </toggle-switch>

                <template v-if="requestAction.type === 'status'">
                    <multiselect
                        v-if="options.status"
                        v-model="requestAction.status"
                        v-bind:options="options.status"
                        track-by="key"
                        label="value"
                        class="level-item is-medium"
                    />
                    <button class="button is-primary" :disabled="checked.length === 0 || !requestAction.status" @click.prevent="changeStatuses()">
                        Status von {{checked.length}} Leistungen ändern
                    </button>
                </template>
                <template v-else>
                    <input-date v-model="requestAction.deadline" class="level-item is-medium" />
                    <button class="button is-primary" :disabled="checked.length === 0 || !requestAction.deadline" @click.prevent="changeDeadlines()">
                        Optionstermin von {{checked.length}} Leistungen ändern
                    </button>
                </template>
            </div>
        </div>
        <template v-slot:traitTags="{row}">
            <i class="material-icons is-small" v-if="row.traitTags && row.traitTags.length > 0" :title="row.traitTags.join(' | ')">sell</i>
        </template>

        <template slot="head:requests" slot-scope="{subrowsVisible}">
            <button class="requests-count is-primary" v-on:click.prevent.stop="toggleRows">
                <i class="material-icons is-transform-transition" :class="{'is-rotated': subrowsVisible}">keyboard_arrow_down</i>
            </button>
        </template>
        <template slot="duration" slot-scope="{row}">
            {{ row.duration }} Tage
        </template>
        <template slot="requests" slot-scope="{row}">
            <button class="requests-count" v-if="row && row.requests" v-on:click.prevent.stop="toggleRow(row)">
                {{row.requests.length}}
            </button>
        </template>
        <template slot="requests.info.personsAsked" slot-scope="{}">-</template>
        <template slot="requests.info.personsReserved" slot-scope="{}">-</template>
        <template slot="requests.requestStatus" slot-scope="{}">-</template>
        <template slot="requests.optionTodo" slot-scope="{}">-</template>
        <template slot="days" slot-scope="{row}" v-if="row && row.days">{{row.days.length}} Tage</template>
        <template slot="info.personsAsked" slot-scope="{row}">{{getPax(row, 'askedAmountWithExtra')}}</template>
        <template slot="info.personsReserved" slot-scope="{row}">{{getPax(row, 'reservedAmountWithExtra')}}</template>
        <template slot="order.client.shortName" slot-scope="{row}">
            <popover class="cell-content" trigger="hover">
                <span class="is-truncated">{{row.order.client.shortName}}</span>

                <template slot="popover">
                    {{row.order.client.shortName}}
                </template>
            </popover>
        </template>
        <template slot="hotel.name" slot-scope="{row}">
            <popover class="cell-content" trigger="hover">
                <request-hotel-name class="is-truncated"  v-bind:row="row" />

                <template slot="popover">
                    <request-hotel-name v-bind:row="row" />
                </template>
            </popover>
        </template>
        <template slot="ferry.name" slot-scope="{row, options}">
            <popover class="cell-content" trigger="hover">
                <request-ferry-name class="is-truncated" v-bind="{row, options}" />

                <template slot="popover">
                    <request-ferry-name v-bind="{row, options}" />
                </template>
            </popover>
        </template>
        <template slot="train.name" slot-scope="{row, options}">
            <popover class="cell-content" trigger="hover">
                <request-train-name class="is-truncated" v-bind="{row, options}" />

                <template slot="popover">
                    <request-train-name v-bind="{row, options}" />
                </template>
            </popover>
        </template>
        <template slot="airline.name" slot-scope="{row, options}" v-bind="{row, options}">
            <popover class="cell-content" trigger="hover">
                <request-airline-name class="is-truncated" v-bind="{row, options}" />

                <template slot="popover">
                    <request-airline-name v-bind="{row, options}" />
                </template>
            </popover>
        </template>
        <template slot="serviceDescription" slot-scope="{row}">
            <popover class="cell-content" trigger="hover">
                <request-other-servicedescription class="is-truncated" v-bind:row="row" />

                <template slot="popover">
                    <request-other-servicedescription v-bind:row="row" />
                </template>
            </popover>
        </template>
        <template slot="hotel_placeholder:info.title" slot-scope="{row}">
            <template v-if="row.hotelType">{{row.hotelType.stars}}* Hotel</template>
            <template v-if="row.place">in {{row.place.name}}</template>
            <template v-else-if="row.area">{{row.area.preposition}} {{row.area.name}}</template>
        </template>
        <template slot="client.shortName" slot-scope="{row}">
            <client-cell v-bind:row="row" v-bind:value="row.client">
                <template v-if="row.client">{{row.client.shortName}}</template>
                <template v-else>-</template>
                <template v-if="row.groupName">({{row.groupName}})</template>
            </client-cell>
        </template>
        <div
            slot="subrow"
            slot-scope="{row, options}"
            v-if="row.requests && row.requests.length > 0 && row.subrowVisible"
        >
            <requests-rows v-bind:requests="row.requests" v-bind:options="options" />
        </div>
    </crud-table>
</template>

<script>
import { saveAs } from 'file-saver';
import {settings} from "@clientSpecific/utilities/settings";

import Loading from '@components/Loading'
import Popover from '@components/Popover';
import { CrudTable, ExportButton } from '@components/CRUD';
import { Client } from '@components/CRUD/Table/Cells'
import { ToggleSwitch, InputDate, Multiselect } from '@components/form';
import UserImage from '@components/UserImage';
import {notifyError, notifySuccess} from '@components/Notification';

import { getStorageFilter, setStorageFilter, currentUser, progressPromise } from '@utilities/functions';
import {exportApi, editRequest, axiosOptions} from "@api";

import { columns, getPax, prepareFilters } from './helpers';
import TableFilters from './Filters';
import RequestsRows from './RequestsRows';

import Requests from './Cells/Requests';
import moment from "moment";
import axios from "axios";
import SimpleMenu from "@components/SimpleMenu.vue";
import DropZone from "./DropZone.vue";
import {defaultUserDashboardFilters} from "@clientSpecific/utilities/defaultValues";

export default {
    data: function () {
        return {
            requestAction: {
                type: 'status',
                deadline: null,
                status: null
            },
            loggedInUser: currentUser(),
            buttonIsLoading: false,
            showSelectFile: false,

            checked: [],
            filters: {
                ...JSON.parse(JSON.stringify({
                    ...defaultUserDashboardFilters(),
                })),
                ...getStorageFilter('user-dashboard/list'),
                type: 'orders'
            }
        }
    },

    computed: {
        type: function () { return this.filters.type },
        isOrders: function () { return this.type === 'orders' },
        isRequests: function () { return this.filters.isRequests && !this.isOrders },

        apiType: function () {
            return this.isRequests ? 'requests' : 'placeholders/find_extended/get'
        },

        isGrantedAdmin: function() {
            return this.loggedInUser && this.loggedInUser.roles && this.loggedInUser.roles.includes('ROLE_ADMIN');
        },
        assignFilter: function() {
            if (this.isOrders || (!this.isOrders && !this.isRequests)) {
                return {'_order[startAt]': 'ASC', '_order[id]': 'ASC'};
            }
            if (this.isRequests) {
                return {'_order[placeholder.startAt]': 'ASC', '_order[startAt]': 'ASC'};
            }
        },



        api: function () {
            if (this.isOrders) {
                return 'orders/user_dashboard';
            } else {
                return `${this.type}_${this.apiType}`
            }
        },

        columns: function () {
            const { orders, requests, placeholders } = columns(this.type);

            if (this.isOrders) {
                return orders;
            } else if (this.isRequests) {
                return requests
            } else {
                return placeholders
            }
        },

        filter: function () {
            return prepareFilters(this.filters)
        },
    },

    mounted: function() {
        this.$layout.vm.$on('tour:user-dashboard:changeType', this.changeType)
        let today = new moment();
        if(settings.userDashboard.resetFilterToToday &&
            (!this.filters.lastUpdate || moment(this.filters.lastUpdate).format('DD.MM.YYYY') !==  today.format('DD.MM.YYYY'))) {
                this.filters.startAt = today.format('DD.MM.YYYY');
        }
    },

    methods: {
        moment,

        changeType: function (type) {
            this.filters = JSON.parse(JSON.stringify({
                ...defaultUserDashboardFilters(),
                _search: this.filters._search,
                users: this.filters.users,
                startAt: this.filters.startAt,
                traitTags: this.filters.traitTags,
                orderStatus: this.filters.orderStatus,
                country: this.filters.country,
                organisation: this.filters.organisation,
                type
            }))
        },

        changeUsers: function (users) {
            this.filters.users = users;
        },

        toggleRow: function (row) {
            this.$refs.table.toggleRow(row);
        },

        toggleRows: function () {
            this.$refs.table.toggleRows()
        },

        handleOrderImport: function () {
            notifySuccess('Die Reisen wurden anhand der hochgeladenen Datei aktualisiert')
            this.showSelectFile = false;
        },

        handleOrderExport: function (api = 'orders/generate-excel', name = 'Reisen.xlsx') {
            this.buttonIsLoading = true;
            axiosOptions(api,
                {
                    ...this.filter,
                    '_itemsPerPage': 100000,
                    '_order[startAt]': 'ASC',
                }, {responseType: 'blob'}).then((response) => {
                this.buttonIsLoading = false;

                saveAs(response.data, name)
            }, error => { notifyError('Der Export konnte nicht erstellt werden! Server Error!'); });
        },

        handleExport: function(language) {
            const apiType = this.isRequests ? 'requests' : 'placeholders'
            const api = `${this.type}_${apiType}`
            const params = this.$refs.table.getParams;

            exportApi(api, {
                ...params,
                _itemsPerPage: 99999,
                language,
                _groups: ['document_read'],
            }, language === 'french' ? 'fr' : 'de_DE').then(response => {
                saveAs(`/files/${response.data.path}`, response.data.path.split('/').pop())
            })
        },

        // Data helpers
        getPax,

        rowURL: function (row) {
            if (this.isOrders) {
                return `/orders/${row.id}`
            } else if (this.isRequests && !!row.order) {
                return `/orders/${row.order.id}`
            } else if (!this.isRequests && !!row.day && row.day.order) {
                return `/orders/${row.day.order.id}`
            }
        },

        changeStatuses: function() {
            this.editRequests({
                requestStatus: {
                    status: this.requestAction.status.key
                }
            }).then(() => {
                notifySuccess('Statuses for requests changed')
            })
        },

        changeDeadlines: function() {
            this.editRequests({
                optionTodo: {
                    status: 'open',
                    dueDate: `${this.requestAction.deadline} 00:00:00`
                }
            }).then(() => {
                notifySuccess('Deadlines for requests changed')
            })
        },

        editRequests: function(data = {}) {

            this.$refs.table.setLoading(true)


            return new Promise((resolve, reject) => {
                progressPromise(
                    this.checked.map(item => {

                        return editRequest({
                            apiEndpoint: this.api,
                            id: item.id,
                            data: {
                                ...data,
                                ...data.optionTodo && item.optionTodo && item.optionTodo.id && {optionTodo: {...data.optionTodo, id: item.optionTodo.id}}
                            },
                            params: '?_groups[]=user_dashboard_read'
                        })
                    }), (completed, total) => {
                    this.$refs.table.setLoading({
                        textInFront: 'Updating',
                        textAfter: `${completed} of ${total}`
                    })
                }).then(responses => {
                    this.requestAction = {
                        type: 'status',
                        deadline: null,
                        status: null
                    }
                    this.$emit('refreshTodos');
                    this.$refs.table.refresh()
                    resolve(responses)
                }, error => {
                    reject(error)
                    this.$refs.table.setLoading(false)
                })
            })
        }
    },

    watch: {
        filters: {
            deep: true,
            handler: function (filters) {
                setStorageFilter('user-dashboard/list', {
                    lastUpdate: new moment(),
                    _search: filters._search,
                    startAt: filters.startAt,
                    endAt: filters.endAt,
                    orderStatus: filters.orderStatus,
                    users: filters.users,
                    tags: filters.traitTags,
                })
            }
        },

        isRequests: function() {
            this.requestAction = {
                type: 'status',
                deadline: null,
                status: null
            }
        }
    },

    components: {
        DropZone,
        SimpleMenu,
        Loading,
        CrudTable,
        ExportButton,
        ToggleSwitch,
        InputDate,
        Multiselect,
        TableFilters,
        UserImage,
        Popover,
        ClientCell: Client,

        RequestsRows,
        ...Requests //Cells
    },
}
</script>
