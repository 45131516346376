<template>
    <div>
        <filter-default v-model="filters" v-bind:options="options" requests>
            <div class="column is-narrow pt-1 pb-1">
                <multiselect
                    v-bind:options="categories"
                    label="value"
                    track-by="key"
                    v-model="category"
                    placeholder="Other Service Type category"
                    class="is-smaller"
                />
            </div>
        </filter-default>
    </div>
</template>

<script>
import value, { options } from '../_mixins';
import FilterDefault from '../Default';
import { Multiselect } from '@components/form'

export default {
    mixins: [ value, options ],

    components: {
        FilterDefault,
        Multiselect
    },

    computed: {
        categories: function () {
            return this.options.otherServiceTypeCategories ? this.options.otherServiceTypeCategories : []
        },

        category: {
            get: function () { return this.categories.find(category => category.key === this.filters.other.otherServiceTypeCategory) },
            set: function (value) { this.filters.other.otherServiceTypeCategory = !!value ? value.key : null }
        }
    }
}
</script>
