var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "filter-default",
        {
          attrs: { options: _vm.options, search: false, placeholders: "" },
          model: {
            value: _vm.filters,
            callback: function ($$v) {
              _vm.filters = $$v
            },
            expression: "filters",
          },
        },
        [
          _c("div", { staticClass: "column is-narrow pt-1 pb-1" }, [
            _c(
              "div",
              { staticClass: "flatpickr-combined" },
              [
                _c("async-select", {
                  staticClass: "is-smaller level-item",
                  attrs: { api: "airports", placeholder: "Abflug" },
                  model: {
                    value: _vm.filters.airline.startAirport,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters.airline, "startAirport", $$v)
                    },
                    expression: "filters.airline.startAirport",
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "level-item" }, [_vm._v("-")]),
                _vm._v(" "),
                _c("async-select", {
                  staticClass: "is-smaller",
                  attrs: { api: "airports", placeholder: "Ankunft" },
                  model: {
                    value: _vm.filters.airline.endAirport,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters.airline, "endAirport", $$v)
                    },
                    expression: "filters.airline.endAirport",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }