<template>
    <div>
        <filter-default v-model="filters" v-bind:options="options" requests>
            <div class="column is-narrow pt-1 pb-1">
                <div class="flatpickr-combined">
                    <async-select
                        api="ports"
                        placeholder="Abfahrtsbahnhof"
                        v-model="filters.train.startTrainStation"
                        v-bind:custom-label="port => port.name"
                        class="is-smaller"
                    />

                    <span>-</span>

                    <async-select
                        api="ports"
                        placeholder="Ankunftsbahnhof"
                        v-model="filters.train.endTrainStation"
                        v-bind:custom-label="port => port.name"
                        class="is-smaller"
                    />
                </div>
            </div>
        </filter-default>
    </div>
</template>

<script>
import value, { options } from '../_mixins';
import FilterDefault from '../Default';
import { AsyncSelect } from '@components/form'

export default {
    mixins: [ value, options ],

    components: {
        FilterDefault,
        AsyncSelect
    }
}
</script>
